import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/order
import { DisconnectHandler } from '../../modules/tt-disconnect';
import { useLocation } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
// import { DisconnectHandlerBase } from ".";
import { GET_CURRENT_USER, LOGOUT_USER, REFRESH_TOKEN } from '../../api/apollo/api';
import { logoutUserHandler, refreshAuthToken } from '../../utils';

const DisconnectHandlerWrapper = () => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  // const { disconnectSocket } = useSelectedContext((context) => context.messenger);
  const token = Cookies.get('access_token');

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);
  const [logoutUser] = useMutation(LOGOUT_USER);
  const [refreshToken] = useMutation(REFRESH_TOKEN);

  const logOut = () => {
    // logoutUserHandler(logoutUser, disconnectSocket);
    logoutUserHandler(logoutUser, () => {});
  };

  const getUser = async () => {
    const user = await getCurrentUser();
    setCurrentUser(user);
  };

  const refreshTokenHandler = () => {
    refreshAuthToken(refreshToken);
  };

  useEffect(() => {
    getUser();
  }, []);

  return token ? (
    <DisconnectHandler
      getUser={currentUser}
      location={location}
      userId={currentUser?.id}
      logoutHandler={logOut}
      accessToken={currentUser?.access_token}
      logoutDelay={1200000}
      domainValue={domainValue}
      refreshTokenHandler={refreshTokenHandler}
    />
  ) : null;
};

export default DisconnectHandlerWrapper;
