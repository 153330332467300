import { gql } from '@apollo/client';

const GET_COUNTRIES = gql`
  query @api(name: market) {
    showCountries {
      id
      name
    }
  }
`;

const GET_INDUSTIAL_GROUPS = gql`
  query @api(name: market) {
    showIndustryGroups {
      id
      name
      code
    }
  }
`;

const GET_SUBINDUSTRIES = gql`
  query @api(name: market) {
    showSubIndustries {
      id
      name
      code
    }
  }
`;

const GET_LEGAL_TYPES = gql`
  query @api(name: market) {
    showCompanyLegalTypes {
      id
      name
    }
  }
`;

const GET_USER = gql`
  query ($id: ID!) @api(name: sso) {
    user(id: $id) {
      id
      email
      firstName: first_name
      lastName: last_name
      avatar
      roleName: role_name
    }
  }
`;

const GET_COMPANIES = gql`
  query ($id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $id) {
      id
      userId: user_id
      companyName: company_name
      email
      industrialGroupId: industry_group_id
      subIndustryId: sub_industry_id
      legalTypeId: legal_type_id
      establishedDate: established_date
      addressLocation {
        countryId: country_id
        zipCode: postcode
        address
      }
      address2
    }
  }
`;

const CREATE_COMPANY = gql`
  mutation ($input: CompanyStoreInput) @api(name: market) {
    createCompany(input: $input) {
      id
    }
  }
`;

const GET_OWN_DRAFTS = gql`
  query @api(name: calculator) {
    getOwnDrafts {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
    }
  }
`;

const GET_DRAFTS_BY_COMPANY = gql`
  query ($id: String!, $type: String) @api(name: calculator) {
    draftsByCompanyAndType(company_id: $id, type: $type) {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
    }
  }
`;

const GET_DRAFT_BY_ID = gql`
  query ($id: ID!) @api(name: calculator) {
    draftById(id: $id) {
      id
      name
      userId: user_id
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      globalAnswers: global_answers
      lastPosition: last_position
      completedAt: completed_at
      stateGraphics: state_graphics
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation ($input: EsgLocalDraftInput!) @api(name: calculator) {
    createEsgDraft(input: $input) {
      id
    }
  }
`;

const CREATE_INACTIVE_DRAFT = gql`
  mutation ($input: EsgLocalDraftInput) @api(name: calculator) {
    createOrGetInactiveEsgDraft(input: $input) {
      id
      userId: user_id
      companyId: company_id
      companyName: company_name
      email
      legalTypeId: legal_type_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      establishedDate: company_date
      countryId: registration_country_id
      zipCode: zip_code
      address1
      address2
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      type
      name
      answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      completedAt: completed_at
      stateGraphics: state_graphics
      lastPosition: last_position
    }
  }
`;

const REPLACE_DRAFT = gql`
  mutation ($input: ReplaceDraftInput, $old_id: ID!) @api(name: calculator) {
    replaceEsgDraft(input: $input, old_id: $old_id) {
      id
      userId: user_id
      companyId: company_id
      companyName: company_name
      email
      legalTypeId: legal_type_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      establishedDate: company_date
      countryId: registration_country_id
      zipCode: zip_code
      address1
      address2
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      type
      name
      answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      completedAt: completed_at
      stateGraphics: state_graphics
      lastPosition: last_position
    }
  }
`;

const UPDATE_DRAFT = gql`
  mutation ($input: EsgLocalDraftUpdateInput!) @api(name: calculator) {
    updateEsgDraft(input: $input) {
      id
    }
  }
`;

const COMPLETE_DRAFT = gql`
  mutation ($input: SaveTtTestInput) @api(name: calculator) {
    saveEsgTest(input: $input) {
      id
      industrialGroupId: industrial_group_id
      testYear: test_year
      environmental
      social
      governance
      productTotal: co_product_total
      packageTotal: co_package_total
      companyTotal: co_company_total
      globalTotal: global
    }
  }
`;

const DELETE_DRAFT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    deleteEsgDraft(id: $id) {
      id
    }
  }
`;

const RENAME_DRAFT = gql`
  mutation ($input: RenameEsgDraftInput!) @api(name: calculator) {
    renameEsgDraft(input: $input) {
      id
    }
  }
`;

const GET_QUESTIONS_FILE = gql`
  query ($input: getFileInput) @api(name: calculator) {
    getQuestionsFile(input: $input) {
      id
      draftId: calc_tt_draft_id
      questionCode: questions_code
      originalName: original_name
      type
      uuidName: file
      description
      state
    }
  }
`;

const DELETE_QUESTIONS_FILE = gql`
  mutation ($id: ID!) @api(name: calculator) {
    deleteQuestionsFile(id: $id)
  }
`;

const EDIT_QUESTIONS_FILES = gql`
  mutation ($files: [EditFileInput]) @api(name: calculator) {
    editFiles(files: $files) {
      id
      questionCode: questions_code
      draftId: calc_tt_draft_id
      type
      originalName: original_name
      uuidName: file
      description
      state
    }
  }
`;

const GET_URL_FOR_PRIVATE_UPLOAD = gql`
  mutation getPrivateUrl($input: sFile) @api(name: calculator) {
    getUrlForPrivateUpload(input: $input) {
      url
      downloadUrl: download_url
    }
  }
`;

const GET_PRELIMINARY_ASSESSMENT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    preliminaryAssessment(id: $id) {
      id
      subIndustryId: sub_industry_id
      country: registration_country_id
      industrialGroupId: industrial_group_id
      testYear: test_year
      environmental
      social
      governance
      productTotal: co_product_total
      packageTotal: co_package_total
      companyTotal: co_company_total
      globalTotal: global
    }
  }
`;

const ADD_UPLOADED_FILE_TO_QUESTION = gql`
  mutation ($input: UploadedFileInput) @api(name: calculator) {
    addUploadedFileToQuestion(input: $input) {
      id
      questionCode: questions_code
      draftId: calc_tt_draft_id
      type
      originalName: original_name
      uuidName: file
      description
      state
    }
  }
`;

const GET_CURRENT_USER = gql`
  query @api(name: sso) {
    currentUser {
      id
      email
      firstName: first_name
      lastName: last_name
      avatar
      roleName: role_name
    }
  }
`;

const LOGOUT_USER = gql`
  mutation @api(name: sso) {
    logout {
      status
      message
    }
  }
`;

const GET_PRICE = gql`
  query ($input: DraftQuickCostInput!) @api(name: calculator) {
    getQuickCalculatorPrice(input: $input) {
      draftId: draft_id
      baseCost: base_cost
      extraCost: extra_cost
      discount
      finalCost: final_cost
    }
  }
`;

const CREATE_CALC_QUESTION = gql`
  mutation ($input: inputBaseQuestion) @api(name: calculator) {
    createCalcQuestion(input: $input) {
      draftId: calc_tt_draft_id
      data
    }
  }
`;

const UPDATE_CALC_QUESTION = gql`
  mutation ($input: inputBaseQuestion) @api(name: calculator) {
    updateCalcQuestions(input: $input) {
      draftId: calc_tt_draft_id
      data
    }
  }
`;

const DELETE_CALC_QUESTION = gql`
  mutation ($input: inputBaseQuestion) @api(name: calculator) {
    deleteCalcQuestions(input: $input) {
      draftId: calc_tt_draft_id
      data
    }
  }
`;

const SET_CALC_ANSWER = gql`
  mutation ($input: inputBaseQuestion) @api(name: calculator) {
    setCalcAnswers(input: $input) {
      draftId: calc_tt_draft_id
      data
    }
  }
`;

const GET_CALC_QUESTION = gql`
  query ($calc_tt_draft_id: ID!, $first: Int, $page: Int) @api(name: calculator) {
    getCalcQuestions(calc_tt_draft_id: $calc_tt_draft_id, first: $first, page: $page) {
      draftId: calc_tt_draft_id
      data
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`;

const GET_USER_RESULT = gql`
  query ($id: String) @api(name: calculator) {
    resultById(id: $id) {
      id
      stateGraphics: state_graphics
      companyName: company_name
      companyId: company_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      registrationCountryId: registration_country_id
      employeesNumber: employees_number
      reportDate: test_year
      createdAt: created_at
      updatedAt: updated_at
      isBlockchain: is_blockchain
      localAnswers: answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      type
      totalResult: result
      productResult: co_product_total
      packageResult: co_package_total
      companyResult: co_company_total
      socialResult: social
      governanceResult: governance
      environmentalResult: environmental
      sharingId: sharing_id
      resourcesDraftId: resources_draft_id
      factorId: factor_id
      name
      files {
        id
        questions_code
        calc_tt_draft_id
        type
        original_name
        uuidName: file
        description
        state
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation ($input: RefreshTokenInput) @api(name: sso) {
    refreshToken(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`;

export {
  CREATE_CALC_QUESTION,
  UPDATE_CALC_QUESTION,
  DELETE_CALC_QUESTION,
  SET_CALC_ANSWER,
  GET_CALC_QUESTION,
  ADD_UPLOADED_FILE_TO_QUESTION,
  GET_PRELIMINARY_ASSESSMENT,
  GET_URL_FOR_PRIVATE_UPLOAD,
  GET_COUNTRIES,
  GET_USER,
  GET_COMPANIES,
  CREATE_COMPANY,
  GET_INDUSTIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_LEGAL_TYPES,
  GET_OWN_DRAFTS,
  GET_DRAFTS_BY_COMPANY,
  CREATE_DRAFT,
  CREATE_INACTIVE_DRAFT,
  REPLACE_DRAFT,
  UPDATE_DRAFT,
  COMPLETE_DRAFT,
  GET_DRAFT_BY_ID,
  DELETE_DRAFT,
  RENAME_DRAFT,
  GET_QUESTIONS_FILE,
  EDIT_QUESTIONS_FILES,
  DELETE_QUESTIONS_FILE,
  GET_CURRENT_USER,
  LOGOUT_USER,
  GET_PRICE,
  GET_USER_RESULT,
};
